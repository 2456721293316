@use "./styles/partials/variables" as *;
@use "./styles/partials/mixins" as *;
@use "./styles/partials/fonts" as *; // TESTED THE FONTS WORKING PROPERLY
@use "./styles/partials/resets" as *; // TESTED RESETS GLOBAL

.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;
}

main {
  flex-grow: 1;
}
