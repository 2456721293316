.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;

  &__text {
    color: #5c667e;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}
